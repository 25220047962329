import React from "react";
import "./App.scss";
import { ReactComponent as Logo } from "./logoAntreem.svg";
import { ReactComponent as Cruciverba } from "./cruciverba.svg";

import ReactGA from "react-ga";

function App() {
  ReactGA.initialize("UA-59238164-13");
  ReactGA.pageview(window.location.pathname + window.location.search);

  ReactGA.ga("send", "pageview", "soluzioni.antreem.com");

  const partners = [
    { imageUrl: "abilab.png", url: "https://www.abilab.it/home" },
    { imageUrl: "alma-mater.png", url: "https://www.unibo.it/it" },
    { imageUrl: "architecta.png", url: "https://www.architecta.it/" },
    {
      imageUrl: "complexity-institute.png",
      url: "https://www.complexityinstitute.it/"
    },
    { imageUrl: "crit.png", url: "https://www.crit-research.it/it/" },
    { imageUrl: "innovami.png", url: "https://www.innovami.it/it/" },
    { imageUrl: "politecnico-di-milano.png", url: "https://www.polimi.it/" },
    { imageUrl: "tomtom.png", url: "https://www.tomtom.com/it_it/" },
    { imageUrl: "virtus.png", url: "https://www.virtus.it/" },
    { imageUrl: "wud.png", url: "https://www.wudrome.it/en/" }
  ];

  return (
    <main>
      <section>
        <div>
          <article className="brand-container">
            <a href="https://antreem.com">
              <Logo />
            </a>
            <h1>Discover. Design. Deliver.</h1>
          </article>

          <article className="brand-subtitle">
            Realizziamo <i>soluzioni digitali</i> di qualit&agrave;
            <br />
            mettendo al centro del progetto le <i>persone</i>
          </article>
        </div>
        <div>
          <Cruciverba />
          <article className="solutions-subtitle">
            Scopri di più su di{" "}
            <a href="https://antreem.com">
              <i>noi</i>
            </a>
            !
          </article>
        </div>
      </section>
      <footer>
        {partners.map(({ imageUrl, url }) => (
          <div>
            <a href={url}>
              <img src={`${process.env.PUBLIC_URL}/partner/${imageUrl}`} />
            </a>
          </div>
        ))}
      </footer>
    </main>
  );
}

export default App;
